
.checkout-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 auto;

    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: minmax(min-content, calc(50% + 72px)) 1fr;

        width: 100%;
        max-width: 100%;
        margin: 0;
    }

    > .left-container {
        width 100%
        max-width: 570px;
        background-color: #fff
        border-right: none;
        margin: 0 auto;
        min-height: 100dvh;

        @media screen and (min-width: 1024px) {
            max-width: 100%;
            border-right: 1px solid #dedede;
            margin: 0;
        }

        > .section-information {
            width 100%
            max-width: 100dvw
            padding: 0 21px 0;
            display: flex;
            flex-direction: column;
            gap: 26px;
            margin-top: 24px;

            @media screen and (min-width: 1024px)  {
                margin-top: 60px;
                padding: 0 22px 0;
                max-width: 660px;
                margin-left: auto;
            }
        }
    }

    >.right-container {
        width 100%
        background-color: #fff;

        @media screen and (min-width: 1024px) {
            width 100%
            background-color: $color-grey-light-80;
            min-height: 100dvh;
        }

        >.section-summary {
            width 100%
            max-width: 100dvw
            padding: 21px;

            display flex;
            flex-direction: column;
            gap: 32px;

            position: relative;

            @media screen and (min-width: 1024px) {
                position: sticky;
                top: 72px;

                padding: 38px;
                width 100%
                max-width: 520px;
                margin-right: auto;

                display flex;
                flex-direction: column;
                gap: 24px;
            }
        }
    }
}

.order-detail-header{
    display: flex;
    align-items: center;
    gap: 16px;
}

.order-detail-container {
    border: 1px solid $color-gray85
    border-radius: 8px
    padding: 16px

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }

    .span-2 {
        grid-column: span 1

        @media screen and (min-width: 1024px) {
            grid-column: span 2
        }
    }
}

.order-detail-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.section-summary-sticky {
    background-color: $color-grey-light-80;

    grid-column: span 2;
}

.section-summary-sticky-accordion {
    width 100%
    max-width: 100dvw
    padding: 21px 21px 0;

    display flex;
    flex-direction: column;
    gap: 21px;
}

.full-page
 overflow: visible !important


.action-confirm-order-container {
    position sticky
    bottom 0
    width 100%
    background linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 50%);
    padding 24px 16px

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-direction: column;

    .ship-date {
        width 100%
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        padding: 80px
        background-color: $color-grey-light-80
        border-radius: 8px
    }
}
